.page {
  display: flex;
  gap: 30px;
  width: 80%;
  margin: 50px auto;
}

.menu {
  flex-shrink: 0;
  min-width: 150px;
  width: 15%;
}

.menu > ul {
  position: sticky;
  top: 110px;
  padding-right: 5px;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
}

.menuItem {
  list-style-type: none;
  margin-left: -30px;
}

.menuItem > a {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1em;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-light-gray);
}

.menuItem > a:hover, .selectedMenuItem > a {
  color: var(--black-text);
}
