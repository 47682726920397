.mg-table__additioanal-cell {
  padding: 16px 14px !important;
}

.editable-field {
  max-width: 400px;
}

.mg-difficult-table__context:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.mg-difficult-table__contact {
  font-weight: 500;
  font-size: 12px;
  color: var(--green);
  line-height: 24px;
}

.mg-difficult-table__contact:hover {
  color: var(--dark-text) !important;
}

.mg-difficult-table__menu.ant-menu-root.ant-menu-vertical {
  box-shadow: 0px 2px 4px rgba(11, 44, 84, 0.12), 0px 6px 24px rgba(43, 63, 103, 0.32);
  border-radius: 4px;
}

.mg-difficult-table .ant-table-tbody .mg-table__chart-cell {
  max-width: 600px;
  width: 100%;
  padding: 0 !important;
  height: 100px;
  margin-left: -60px;
  padding-left: 0 !important;
  overflow-y: hidden;
}

.mg-difficult-table .ant-table-tbody .mg-table__chart-cell > div {
  height: 100%;
}
