.mg-difficult-table-th {
  display: flex;
  align-items: center;
}

.mg-difficult-table__wrap {
  position: relative;
}

.mg-difficult-table .ant-table-thead .ant-table-cell {
  padding: 36px 12px 12px 12px;

  border-bottom: 1px solid var(--chart-line-backgroud);
  background-color: var(--gray-bg);
}

.mg-difficult-table .ant-table-thead .ant-table-cell::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.mg-difficult-table .ant-table-thead .ant-table-cell:first-child {
  padding-left: 65px;
}

.mg-difficult-table-th__tooltip-wrapper {
  margin-top: -30px;
  padding-top: 30px;
}

.mg-difficult-table-th__tooltip-wrapper:hover .mg-custom-tooltip {
  display: block;
}

.mg-custom-tooltip {
  display: none;
}

.mg-difficult-table-th__title {
  display: flex;
  align-items: center;

  position: relative;

  cursor: pointer;

  font-size: 17px;
  font-weight: normal;
  color: var(--light-text);
}

.mg-difficult-table-th__title div a {
  width: fit-content;
  max-width: 16px;
  color: var(--light-text);
  margin-left: 5px;
}

.mg-difficult-table-th__title > div {
  display: flex;
  align-items: center;
}

.mg-difficult-table-th__title::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  content: '';
  z-index: -1;
}

.mg-difficult-table-th__title_name {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-width: 60px;
}

.mg-difficult-table-th__title_name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: min-content;
}

.mg-difficult-table-th__title_name div {
  margin: 0px 5px;
  fill: var(--light-text);
}

.mg-table .ant-table-thead th {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  background-color: transparent !important;
  border: 0;
}

.mg-difficult-table .ant-table-thead th .mg-difficult-table-th__title {
  max-width: 150px;
  width: max-content;
}

.mg-difficult-table .ant-table-tbody .ant-table-cell {
  padding: 12px !important;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  background-color: white;
  color: var(--dark-text);
  border-bottom: 1px solid var(--light-gray) !important;
}

.mg-difficult-table .ant-table-tbody .ant-table-cell:first-child {
  padding-left: 65px !important;
}

.mg-difficult-table .ant-table-content {
  overflow: hidden auto !important;
}

.mg-difficult-table .ant-table-row:hover .ant-table-cell {
  background-color: var(--gray-bg);
}

.mg-difficult-table .ant-table-tbody .ant-table-cell:first-child .mg-difficult-table__text-wrap::before {
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: white;
  height: 2px;
  width: 55px;
  content: '';
}

.mg-difficult-table .ant-table-tbody .ant-table-cell .mg-difficult-table__text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mg-difficult-table .ant-table-cell::before {
  display: none;
}

.ant-table-content {
  font-size: 18px;
  line-height: 24px;
}

.ant-pagination-prev,
.ant-pagination-item,
.ant-pagination-next {
  width: 36px;
  height: 36px;
  margin-right: 12px;
}

.ant-pagination-item,
.ant-pagination-item-link {
  border: 0 !important;
  border-radius: 4px !important;
  background-color: var(--step-bg) !important;
}

.ant-pagination-item-active {
  background-color: #fff !important;
}

.ant-pagination-item a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Dubai;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.ant-pagination-item a:hover {
  color: var(--light-text);
}

.ant-pagination-item-link svg {
  color: var(--light-text);
}

.ant-pagination-disabled .ant-pagination-item-link svg {
  color: var(--pagination-disabled);
}

.mg-table__pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 0 120px 0;
  background-color: var(--gray-bg);
  margin-top: 72px;
}

.mg-table__wrap {
  z-index: 2;
}

.mg-difficult-table {
  margin-top: 20px;
}

.scroll-container .ps__thumb-x {
  background-color: #c4d3db;
  border-radius: 4px;
  height: 16px !important;
}

.scroll-container .ps__rail-x {
  top: 0;
  bottom: unset;
  opacity: 1 !important;
}

.scroll-container .ps__rail-x:hover > .ps__thumb-x {
  background-color: #c4d3db;
}

.scroll-container .ps__rail-x {
  height: 20px;
  margin-top: -2px;
  background-color: var(--gray-bg) !important;
}

.scroll-container .ps__rail-x:hover > .ps__thumb-x,
.scroll-container .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #c4d3db;
}

.scroll-button {
  position: absolute;
  top: 58px;
  width: 32px;
  height: 16px;
  min-height: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: background-color 0.25s ease;
}

._without-sort {
  top: -18px;
}

.scroll-button._right {
  right: 76px;
}

.scroll-button._left {
  left: 76px;
}

.scroll-button._left svg {
  transform: rotate(180deg);
}

.scroll-button svg {
  transition: fill 0.25s ease;
  fill: var(--divider-background);
}

.scroll-button:hover svg {
  fill: var(--dark-text);
}

.scroll-button:hover {
  background-color: #c3d2db;
}

.mg-difficult-table .ant-table-thead .ant-table-cell-fix-right {
  padding-right: 12px;
}

.mg-difficult-table._scroll-visible .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px #00000026;
}

.mg-difficult-table._scroll-visible .ant-table-cell-fix-right-first:after {
  box-shadow: inset -10px 0 8px -8px #00000026;
}

.sort-scrollbar {
  width: 100%;
}

.sort-scrollbar .ps__rail-x {
  opacity: 0 !important;
}

.sort-scrollbar .ps__rail-y {
  opacity: 0 !important;
}

.collapse-search .ant-collapse-header {
  padding: 0 !important;
}

.collapse-search {
  margin-bottom: 6px;
}

.collapse-search .ant-collapse-content-box {
  padding: 12px 27px 15px 27px !important;
}

.collapse-search .ant-collapse-content-active {
  background-color: var(--light-gray-2) !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.scroll-search-column .ps__rail-y {
  right: 0 !important;
  left: unset !important;
}

.scroll-search-column .ps__thumb-y {
  scrollbar-width: auto !important;
  background: #c4d3db !important;
  border-radius: 4px !important;
}

.mg-difficult-table .scroll-container .ps__rail-x:hover > .ps__thumb-x,
.mg-difficult-table .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #c4d3db;
}

@media (max-width: 576px) {
  .mg-difficult-table .ant-table-tbody .ant-table-cell:first-child {
    padding-left: 24px !important;
  }

  .mg-difficult-table .ant-table-thead .ant-table-cell:first-child {
    padding-left: 24px !important;
  }
}
