.addFilterButton {
  margin: 0;
  padding: 0;
  margin-right: 39px;
  z-index: 10;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  border: none;
  box-shadow: none;
  color: var(--table-icons) !important;
}

.addFilterButton:hover {
  color: var(--dark-text) !important;
}

.addFilterButton:hover svg {
  fill: var(--dark-text);
}

.addFilterButton svg {
  fill: var(--table-icons);
  transition: fill .25s ease;
  margin-right: 7px;
}

.rangeWrap {
  position: relative;
  display: flex;
  max-width: 80%;
  justify-content: space-between;
}

.filterWrap {
  display: flex;
  /* width: 85%; */
  flex-grow: 1;
  /* margin-right: 50px; */
  justify-content: space-between;
  align-items: center;
}

.filterWrapGroupItem {
  margin-left: 100px;
}

.filterTitle {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  color: var(--light-text);
  margin-right: 20px;
}

.filterRow {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 6px;
  padding-left: 24px;
}

.textInput {
  min-width: 500px;
  margin-bottom: 0;
}

.inputWrap {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.rangeInput {
  display: flex;
  align-items: center;
  /* max-width: 200px; */
}

.rangeInput:first-child {
  margin-right: 40px;
}

.rangeInput>span {
  margin-right: 6px;
  margin-bottom: 10px;
}

.rangeInputNumber {
  width: min-content;
  min-width: 100px;
}

.rangeInputNumber div {
  margin-bottom: 0;
  margin-right: 7px;
}

.selectedIndustryLabel {
  min-width: 500px;
  margin-bottom: 0;
}

.selectedIndustryLabel input {
  cursor: pointer;
}

.filterError {
  position: absolute;
  bottom: 5px;
  left: 0;
  color: var(--error);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.rangeError {
  padding-bottom: 20px;
}

.removeFilterButton {
  /* position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-58%);
  margin: 0;
  padding: 0;
  margin-left: 26px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px; */
  width: auto;
  border: none;
  box-shadow: none;
}

.removeFilterButtonGroupItem {
  right: 60px;
}

.removeFilterButton svg {
  fill: var(--table-icons);
  transition: fill .25s ease;
}

.removeFilterButton:hover svg {
  fill: var(--error);
}

.typeCol {
  min-width: 150px;
  padding-left: 12px;
  color: #5F7794;
}

@media (max-width: 1575px) {
  .inputWrap {
    justify-content: flex-start;
  }
}
