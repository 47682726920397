.selectWrapper {
  position: relative;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.optionWrapper {
  display: flex;
  flex: 1;
}

.tickerColumn {
  display: flex;
  width: 20%;
  color: #5F7794;
  margin-right: 12px;
}

.companyColumn {
  width: 80%;
  text-align: left;
  margin-right: 12px;
}
