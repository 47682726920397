.customScreen .settingsColumns .ant-table-thead th:nth-child(2),
.customScreen .settingsColumns .ant-table-tbody td:nth-child(2) {
  width: 20%;
}

.customScreen .settingsColumns .ant-table-thead th:nth-child(7),
.customScreen .settingsColumns .ant-table-tbody td:nth-child(7) {
  width: 75%;
  min-width: 700px;
}

@media (max-width: 762px) {

  .customScreen .settingsColumns .ant-table-thead th:nth-child(5) div {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .customScreen .settingsColumns .ant-table-thead th:nth-child(5) div button {
    margin-left: 0;
  }
}

@media (max-width: 715px) {

  .customScreen .settingsColumns .ant-table-thead th:nth-child(2),
  .customScreen .settingsColumns .ant-table-tbody td:nth-child(2) {
    width: auto;
  }

  .customScreen .settingsColumns .ant-table-thead th:nth-child(5),
  .customScreen .settingsColumns .ant-table-tbody td:nth-child(5) {
    width: auto;
  }
}
