.paddings {
  padding: 60px 106px 0 106px;
}

.page {
  position: relative;
  background-color: white;
  height: 100%;
}

.spinnerWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 19;
}

.numberWrap {
  text-align: 'right';
  width: '100%';
  margin: 0;
  padding-right: 25px;
}

.companyLink {
  position: relative;
  color: var(--dark-text);
}

.companyLink:hover {
  color: var(--green-text);
}

.companyLink:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.saveBtn {
  align-self: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
}

.filterWrap {
  position: relative;
  display: flex;
  flex-direction: column;
}

.addIdeaBtn {
  padding: 0;
  z-index: 10;
  cursor: pointer;
}

.addIdeaBtn::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
}

.addIdeaBtn svg {
  transition: fill 0.25s ease;
  fill: var(--table-icons);
}

.addIdeaBtn:hover svg {
  fill: var(--dark-text);
}

.deleteBtn {
  margin: 0 0 0 auto;
  padding: 0 106px 7px 0;
  width: min-content;

  font-size: 12px;
  line-height: 24px;
  color: var(--green-text) !important;
  transition: color 0.25s ease;
}

.deleteBtn:hover {
  margin: 0 0 0 auto;
  padding: 0 106px 7px 0;
  width: min-content;

  font-size: 12px;
  line-height: 24px;
  color: var(--dark-text) !important;
}

.deleteBtn svg {
  margin-right: 7px;

  fill: var(--green-text);
  transition: fill 0.25s ease;
}

.deleteBtn:hover svg {
  fill: var(--dark-text);
}

@media (max-width: 600px) {
  .deleteBtn {
    padding: 0 24px 7px 0;
  }
}

@media (max-width: 576px) {
  .paddings {
    padding: 60px 24px 0 24px;
  }
}
