.footer {
    background-color: var(--footer-bg);
}

.footerPadding {
    padding: 90px 106px;
}

.footerPaddingSmallScreen {
    padding: 35px 53px;
}

.footerMenu {
    color: var(--green-text);
    font-size: 16px;
    line-height: 24px;
}

.footerMenu p {
    margin: 0;
    color: var(--text-footer-blue);
}

.footerMenu a {
    text-decoration: underline;
    color: var(--text-footer-blue);
}

.footerMenu a:hover {
    color: white;
}

.divider {
    background-color: #4995D1;
    margin: 12px 0 36px 0;
}

.footerLinksWrap {
    color: var(--text-footer-blue);
}

.footerLinksWrap div:first-of-type p {
   margin: 0 50px 0 0;
}

.footerLinks a {
    display: flex;
    margin-right: 39px;
}

.footerLinks a:hover svg {
    fill: var(--green-text);

}

.footerLinks a svg {
    fill: #5080A7;
    transition: fill 0.25s ease;
}

.textBlock {
  margin-bottom: 36px;
}

.textBlock h2 {
  color: var(--text);
}

.textBlock p:last-child {
  margin-bottom: 0;
}

.textBlock ol {
  list-style-type: lower-alpha;
}

.textBlock li ul {
  list-style-type: disc;
}

.textSection > p:not(:last-child) {
  margin-bottom: 36px;
}

/* .termsList ol {
  counter-reset: section;
  list-style-type: none;
}

.termsList li:before {
  counter-increment: section;
  content: counters(section, ".") " ";
} */

@media (max-width: 768px) {
  .footerLinksWrap div:first-of-type p {
    margin: 0;
 }
}

@media (max-width: 395px) {
  .footerPaddingSmallScreen {
    padding: 30px 20px 30px 20px;
  }
}
