.mgHeaderMenuLeft {
  float: right;
  width: 290px;
  background-color: white;
  justify-content: flex-end;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
  border-bottom: none;
  text-transform: uppercase;
}

.mgHeaderMenuLeftAuth {
  flex: 1;
}

.arrowTop {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.25s ease, transform 0.25s ease;
}

.mgHeaderMenuLeft .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 20px;
}

.mgHeader {
  position: fixed;
  width: 100%;
  min-height: 96px;
  z-index: 20;
  padding: 0 106px;
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(53, 63, 82, 0.5);
  display: flex;
  align-items: center;
}

.mgHeaderMenu a {
  color: var(--text-gray) !important;
}

.mgHeaderMenu {
  position: relative;
  height: 100%;
  color: var(--text-gray) !important;
  padding: 0 12px !important;
  z-index: 2;
}

.mgHeaderMenu:hover {
  color: var(--dark-text) !important;
}

.mgHeaderMenuSelected .arrowTop {
  opacity: 1;
  transform: translateY(20px);
}

.mgHeaderMenuProfile {
  margin-left: 10px;
}

.hoverMenuVisible {
  opacity: 1 !important;
  visibility: visible !important;
}

.hoverMenu {
  position: absolute;
  top: 87%;
  left: 0;
  width: 100%;
  opacity: 0;
  padding: 36px 106px;
  visibility: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: white;
  z-index: 5;
  transition: opacity 0.2s, visibility 0.2s;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(53, 63, 82, 0.5);
}

.hoverMenu:before {
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: white;
  content: '';
}

.mgHeaderMenu:hover a {
  color: var(--dark-text) !important;
}

.mgHeaderMenu:hover .profileWrap svg {
  fill: var(--dark-text);
}

.mgHeaderMenu:hover .arrowTop {
  opacity: 1;
  transform: translateY(20px);
}

.mgHeaderMenu:after {
  display: none !important;
}

.mgHeader .mgHeaderMenu {
  display: flex;
  align-items: center;
}

.profileWrap {
  display: flex;
  align-items: center;
}

.profileWrap svg {
  fill: var(--text-gray);
  transition: fill 0.25s ease;
  margin-right: 14px;
}

.profileWrap svg.profileImageLogged {
  fill: var(--green-text);
  transition: fill 0.25s ease;
  margin-right: 14px;
}

.subMenu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin: 0;
  padding-left: 0;
  width: 100%;
  max-height: 400px;
}

.search {
  position: absolute;
  right: 370px;
}

.search button {
  padding: 0;
}

.search button svg {
  fill: #9babb8;
  transition: fill 0.25s ease;
}

.search:hover svg {
  fill: var(--dark-text);
  transition: fill 0.25s ease;
}

.search * {
  margin-bottom: 0;
  max-width: 265px;
}

.subMenuItemLi {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-right: 36px;
  margin-bottom: 24px;
  min-width: 166px;
}

.subMenuItemLi a {
  color: var(--green-text);
  margin-bottom: 12px;
}

.subMenuItemLi a:hover {
  color: var(--dark-text);
}

.menuText {
  position: relative;
  display: block;
  width: 230px;
  margin-bottom: 12px;
  margin-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  line-height: 24px;
  color: var(--dark-text);
  text-transform: none;
}

.subMenuItemLi .subMenuTitle {
  min-width: 200px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  position: relative;
  color: #8c9fae;
  padding-bottom: 10px;
  margin-bottom: 24px;
}

.subMenuItemLi .subMenuTitle::after {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #8c9fae;
  content: '';
}

.profileImageLogged {
  margin-right: 14px;
}

.mgHeaderMenuSelected .profileWrap svg {
  fill: var(--dark-text);
}

.mgHeaderMenuWrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.mgHeaderMenuWrap svg {
  fill: var(--text-gray);
  transition: fill 0.25s ease;
}

.mgHeaderMenuWrap svg:hover {
  fill: var(--dark-text);
}

.mgHeaderMenuWrapVisibleSearch {
  justify-content: space-between;
}

.mgHeaderMenuSelected a {
  color: var(--dark-text) !important;
}

.mgHeaderMenuSelected {
  color: var(--dark-text) !important;
}

.linksWrapGrid {
  display: grid;
  grid-gap: 0 18px;
  grid-template-columns: repeat(2, 1fr);
}

.linksWrap {
  display: flex;
  flex-direction: column;
}

.headerLink {
  max-width: 300px;
  width: fit-content;
  position: relative;
  word-wrap: break-word;
}

.headerLink::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  transform: translate(-50%, -50%);
  content: '';
}

.resourcesItem {
  margin-right: auto;
}

.customScreeningItem {
  cursor: pointer;
  color: var(--green-text);
  transition: 0.25s ease;
}

.customScreeningItem:hover {
  color: var(--dark-text);
}

.subMenuProfile {
  justify-content: flex-end;
}

@media (max-width: 1270px) {
  .hoverMenu {
    justify-content: flex-start;
  }
}

@media (max-width: 1170px) {
  .search input {
    min-width: 170px;
  }
  .search {
    right: 340px;
  }
}

@media (max-width: 1100px) {
  .mgHeaderMenuLeftAuth {
    width: 300px;
    flex: none;
  }

  .search {
    right: 420px;
  }
}

@media (max-width: 950px) {
  .mgHeaderMenuLeft {
    width: 270px;
  }

  .search {
    right: 300px;
  }
}

@media (max-width: 875px) {
  .mgHeaderMenuLeft {
    width: 230px;
  }

  .search {
    right: 280px;
  }
}

@media (max-width: 768px) {
  .mgHeader {
    padding: 0 53px;
  }

  .hoverMenu {
    padding: 18px 53px;
  }

  .logo {
    left: 53px;
  }

  .subMenuItemLi {
    margin-right: 18px;
  }

  .search {
    right: 250px;
  }

  .mgHeaderMenuWrapVisibleSearch {
    justify-content: flex-end;
  }

  .mgHeaderMenuWrapVisibleSearch input {
    left: 0;
  }
}

@media (max-width: 670px) {
  .headerLink {
    max-width: 190px;
  }
}

@media (max-width: 530px) {
  .mgHeaderMenuLeft {
    width: 120px;
  }

  .search {
    right: 130px;
  }
}

@media (max-width: 480px) {
  .hoverMenu {
    justify-content: flex-start;
    padding: 26px 18px;
  }

  .subMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 420px) {
  .linksWrapGrid {
    grid-template-columns: none;
  }
}

@media (max-width: 395px) {
  .mgHeader {
    padding: 0 20px;
  }

  .logo {
    left: 20px;
  }

  .search {
    right: 90px;
  }
}