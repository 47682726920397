.table-sort-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16px;
  margin-left: 12px;
  cursor: pointer;
}

.table-sort-wrap ._active {
    fill: var(--green);
}

.table-sort-wrap svg {
    fill: #B5C5D2;
    transition: fill .25s ease;
}
