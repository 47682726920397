.formWrap {
    width: 100%;
    display: flex;
    justify-content: center;
}

.form {
    display: flex;
    width: 100%;
    justify-content: center;
}

.formItem {
    margin-bottom: 0;
    display: flex;
    max-width: 290px;
    width: 100%;
    justify-content: center;
}