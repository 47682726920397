.mg-table__chart-cell {
    width: 200px;
}

.mg-table__additioanal-cell {
    padding: 16px 14px!important;
}

.editable-field {
    max-width: 200px;
}

.mg-table__wrap .mg-table .ant-table-cell__green {
    background-color: var(--light-green-background) !important;
}

.mg-table__wrap._white-row .mg-table .ant-table-cell__red {
  background-color: #FFE3EF !important;
}

.mg-table__wrap._white-row .mg-table .ant-table-cell__blue {
  background-color: #D8EEFF !important;
}

.mg-table__wrap._white-row .mg-table .ant-table-cell__violet {
  background-color: #DEE4F8 !important;
}
