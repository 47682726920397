.collapseHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: var(--green);
  text-transform: uppercase;
}

.title svg {
  margin-right: 6px;
  fill: var(--green);
  margin-top: -5px;
}


.title svg:last-child {
  transform: rotate(180deg);
  margin-left: 14px;
}

.openTitle svg {
  fill: var(--dark-text);
}

.openTitle {
  color: var(--dark-text);
}

.openTitle svg:last-child {
  transform: rotate(0);
}

.collapseWrap {
  display: flex;
  flex-direction: column;
  padding: 0 106px;
  background-color: var(--gray-bg);
}

.childrenWrap {
  display: flex;
  flex-direction: column;
}

.showLines {
  display: flex;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #8C9FAE;
  line-height: 24px;
}

.showLines span {
  margin-left: 16px;
  display: block;
  color: var(--green);
}

.addColumnButton {
  padding: 0;
  margin: 0;
  margin-bottom: -50px !important;
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  background-color: var(--gray-bg) !important;
  line-height: 24px;
  width: auto;
  color: var(--green-text) !important;
  z-index: 2;
}

.addColumnButton:hover {
  padding: 0;
  margin: 0;
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  color: var(--dark-text) !important;
}

.addColumnButton:hover svg {
  fill: var(--dark-text) !important;
}

.addColumnButton svg {
  fill: var(--green-text);
  transition: fill .25s ease;
  margin-right: 14px;
}

.addColumnButton::after {
  display: none !important;
}

.rowsMenuItem {
  font-weight: 500;
  font-size: 12px;
  color: var(--green);
  line-height: 24px;
}

.rowsMenuItem:hover {
  color: var(--dark-text) !important;
}

.selectedItem {
  color: var(--dark-text) !important;
  background-color: var(--gray-bg) !important;
}

@media (max-width: 600px) {
  .collapseWrap {
    padding: 0 24px;
  }
}

@media (max-width: 360px) {
  .collapseWrap {
    padding: 0 4px;
  }
}
