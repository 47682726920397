:root {
    --black-text: #011B24;
    --light-blue: #DEF1F8;
    --blue: #0774A3;
    --light-violet: #DEE4F8;
    --green: #008DA0;
    --green-light: #64D7E1;
    --green-light-disabled: #0F5A6A;
    --green-disabled: #C1DBDF;
    --green-bright: #DEF8E5;
    --text: #2B4566;
    --light-text: #324964;
    --dark-text: #0B2C54;
    --title-text-blue: #4995D1;
    --text-blue: #177ED0;
    --text-medium-blue: #003A70;
    --text-medium-blue-disabled: #02444E;
    --text-light-blue: #067396;
    --green-text: #00A0AF;
    --text-light-green: #D5FEFF;
    --text-light-green-title: #0774A2;
    --text-footer-blue: #83D6D8;
    --text-medium-green: #84D6D8;
    --text-bright-green: #0774A2;
    --text-dark-green: #008D96;
    --text-gray: #6F8FA8;
    --text-light-gray: #8C9FAE;
    --gray-bg: #F3F6F8;
    --green-bg-light: #EBF8F6;
    --gray-input-border: #8AADCB;
    --gray-input-placeholder: #9CAEBD;
    --button-disabled: #C8DBE2;
    --pagination-disabled: #C1CFDB;
    --step-bg: #DDE7EE;
    --footer-bg: #013041;
    --step-bg: #DDE7EE;
    --error: #AC447C;
    --subscriptions-icons-gray: #92B8C4;
    --subscriptions-icons-hover: #A9E3E3;
    --chart-line-backgroud: #A6B8C7;
    --yellow: #D48851;
    --black: #011B24;
    --light-green: #E7F7F9;
    --text-sidebar: #8496AD;
    --light-green-background: #D2F3E9;
    --divider-background: #8C9FAE;
    --light-gray-2: #E1E9EE;
    --light-gray: #D2DAE0;
    --table-border: #C3D2DB;
    --table-icons: #A3BBC6;
    --subscribers-background: #F8FAFB;
    --scroll: #C4D3DB;
    --tooltip-color: #008A93;
    --warning: #e61515;
  }
