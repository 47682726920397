.mg-select__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
}

.mg-select .ant-select-selection-item {
    text-align: left;
}

.mg-select__container {
    width: 100%;
}

.mg-select {
    position: relative;
    font-size: 18px;
    line-height: 36px;
    color: var(--text);
}

.mg-select::placeholder {
    color: var(--gray-input-placeholder);
}

.mg-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--gray-input-border);
    height: 36px;
    border-radius: 4px;
    padding-right: 40px;
}

.mg-select:not(.ant-select-customize-input):hover .ant-select-selector {
    border-color: var(--green-text);
}

.ant-select-item-option-content {
    font-size: 18px;
    line-height: 36px;
    color: var(--text);
}

.ant-select-focused:not(.ant-select-disabled).mg-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--green-text);
    box-shadow: none;
}

.mg-text-select .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--green-text);
}

.mg-text-select .ant-select-selection-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--green-text);
}

.mg-text-select.ant-select .ant-select-selector {
    border: none;
    padding: 0;
    padding-right: 15px;
}

.mg-text-select.ant-select  {
    width: auto;
}

.mg-text-select.ant-select-focused.ant-select .ant-select-selector {
    box-shadow: none!important;
     border: none!important;
}

.mg-select-dropdown .ant-select-item-option-selected {
    background-color: white;
    color: var(--dark-text);
}

.mg-select-dropdown {
    min-width: 150px!important;
}

.mg-select-arrow {
    position: absolute;
    bottom: 15px;
    right: 10px;
    fill: var(--gray-input-border);
    transition: transform 0.25s ease;
}

.mg-text-select ~ .mg-select-arrow {
    fill: var(--green-text);
}

.mg-select-arrow._open {
    transform: translateY(-50%) rotate(180deg);
}

.ant-select-selection-placeholder {
    text-align: left;
}