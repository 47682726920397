.divider {
    background-color: var(--divider-background);
    margin-top: 0;
    margin-bottom: 24px;
}

.filtersWrap {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    padding-right: 20px;
}

.filterItem {
   display: flex;
   justify-content: space-between;
   width: 100%;
   padding: 12px 24px;
   background-color: var(--gray-bg);
   border-radius: 4px;
   margin-bottom: 6px;
   cursor: pointer;
}

.filterItem svg { 
    fill: var(--table-icons);
    transition: fill .25s ease;
}
.filterItem svg { 
    fill: var(--table-icons);
    transition: fill .25s ease;
}

.titleModal {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    padding-top: 48px;
    color: var(--text-light-gray);
}

.deleteButton {
    padding: 0;
    margin: 0;
    min-width: unset;
    box-shadow: none;
    width: auto;
}

.deleteButton:hover svg {
   fill: var(--dark-text);
}