.topPanel:before {
  top: 54px;
  height: 57px;
}

.poll {
  display: flex;
}

.poll .deleteBtn {
  color: var(--table-icons);
  margin-top: 15px;
  margin-left: 13px;
  transition: color 0.25s ease;
}

.poll .deleteBtn:hover {
  color: var(--dark-text);
}

.poll .sectionsWrapper {
  width: fit-content;
  border-bottom: 1px solid var(--divider-background);
  padding: 10px;
}

.poll:not(:last-of-type) {
  margin-bottom: 10px;
}

.poll .section {
  display: flex;
}

.poll .section > *:not(:last-child) {
  margin-bottom: 30px;
}

.poll .section .title {
  width: 70px;
  font-size: 18px;
  color: #5f7794;
}

.poll .section > *:not(:last-child) {
  margin-right: 50px;
}

.poll .answersList {
  display: flex;
  flex-direction: column;
}
.poll .answersList > *:not(:last-child) {
  margin-bottom: 10px;
}

@media (max-width: 681px) {
  .topPanel:before {
    height: 81px;
  }
}

@media (max-width: 574px) {
  .topPanel:before {
    top: 90px;
  }
}

@media (max-width: 644px) {
  .topPanel:before {
    height: 81px;
  }
}
