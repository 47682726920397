.paddings {
    padding: 60px 106px 0 106px;
}

.page {
    background-color: white;
    height: 100%;
}

.numberWrap {
    text-align: 'right';
    width: '100%';
    margin: 0;
    padding-right: 25px;
}

.saveBtn {
    align-self: flex-end;
    margin-top: 24px;
    margin-bottom: 24px;
}

.companyLink:hover {
    color: var(--green-text);
}

.saveBtn {
    position: absolute;
    bottom: 24px;
    right: 106px;
}

.filterWrap {
    position: relative;
    display: flex;
    flex-direction: column;
}

@media (max-width: 576px) {
    .paddings {
        padding: 60px 24px 0 24px;
    }    
}