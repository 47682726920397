.row-dragging {
  display: flex;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
  background-color: var(--light-gray-2);
  border-bottom: 1px solid var(--table-border);
}

.row-dragging td {
  padding: 12px 0 6px 12px;
}

.row-dragging td:first-child {
  width: 30px;
  padding-left: 10px;
}

.row-dragging td:nth-child(2) {
  width: 20%;
}

.row-dragging td:nth-child(3) {
  margin-right: 6px;
}

.row-dragging td:nth-child(4) {
  padding-right: 27px;
}

.row-dragging td:nth-child(5) {
  width: 75%;
  border-left: 1px solid var(--table-border);
}

.row-dragging .drag-visible {
  visibility: visible;
}

.dragged {
  cursor: grab;
}

.actionIcon {
  padding: 0 3px;
}

.actionIcon.selected svg {
  fill: var(--text-medium-blue);
}

.drag-visible svg,
.actionIcon svg {
  fill: var(--table-icons);
}

.row-dragging td:first-child svg,
.actionIcon:hover svg {
  fill: var(--dark-text);
  transition: fill .7s ease;
}

.settingsColumns .ant-table {
  background-color: var(--gray-bg);
}

.settingsColumns .ant-table-thead th {
  padding-left: 24px;
  padding-bottom: 14px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--light-text);
  background-color: transparent;
  border-bottom: 1px solid var(--table-border);
}

.settingsColumns .ant-table-thead th::before {
  display: none;
}

.settingsColumns .ant-table-thead th:first-child {
  padding-left: 0;
}

.settingsColumns .ant-table-tbody td {
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
  background-color: var(--gray-bg) !important;
  border-bottom: 1px solid var(--table-border);
  cursor: pointer;
}

.settingsColumns .ant-table-tbody td:first-child {
  width: 30px;
  padding-left: 10px;
}

/* .settingsColumns .ant-table-tbody td:nth-child(4) {
  padding-right: 27px;
}

.settingsColumns .ant-table-tbody td:nth-child(5) {
  border-left: 1px solid var(--table-border);
} */

.settingsColumns .ant-table-tbody td:nth-child(6) {
  padding-right: 27px;
}

.settingsColumns .ant-table-tbody td:nth-child(7) {
  border-left: 1px solid var(--table-border);
}

.settingsColumns .ant-table-tbody .ant-table-cell {
  padding-top: 12px;
  padding-bottom: 6px;
}

.settingsColumns .ant-table-tbody .ant-table-cell.drag-visible:hover svg {
  fill: var(--dark-text);
}

.settingsColumns .ant-table-tbody .ant-table-cell.drag-visible svg {
  transition: fill .25s ease;
  margin-top: 5px;
  margin-right: 5px;
}

.settingsColumns tr:hover td {
  background-color: var(--light-gray-2) !important;
}

.settingsColumns .scroll-container {
  padding-top: 4px;
  padding-bottom: 60px;
}

.settingsColumns .scroll-container .ps__thumb-y {
  width: 16px !important;
  margin-right: -2px;
  background-color: #C4D3DB;
  border-radius: 4px;
}

.settingsColumns .scroll-container .ps__rail-y {
  width: 16px;
  right: 0;
  background-color: #C4D3DB;
  border-radius: 4px;
  opacity: 1 !important;
}

.settingsColumns .scroll-container .ps__rail-y:hover>.ps__thumb-y,
.settingsColumns .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #C4D3DB;
}

.settingsColumns .scroll-container .ps__rail-y {
  height: 30px;
  background-color: #E1E9EE !important;
}

.settingsColumns .ant-table-body {
  overflow-y: unset !important;
}

.settingsColumns .ant-table-cell-scrollbar {
  box-shadow: none;
}

@media (max-width: 430px) {
  .settingsColumns .ant-table-thead .ant-table-cell:nth-child(2) button {
    flex-wrap: wrap-reverse;
  }

  .settingsColumns .ant-table-thead .ant-table-cell:nth-child(5) div {
    margin-left: -35px;
  }
}
