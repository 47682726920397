.addColumnButton {
  padding: 0;
  margin: 0 0 0 -50px;
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  background-color: var(--gray-bg) !important;
  line-height: 24px;
  width: auto;
  border: none;
  box-shadow: none;
  color: var(--green-text) !important;
  z-index: 2;
}

.addColumnButton:hover {
  padding: 0;
  margin: 0 0 0 -50px;
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  color: var(--dark-text) !important;
}

.addColumnButton:hover svg {
  fill: var(--dark-text) !important;
}

.addColumnButton svg {
  fill: var(--green-text);
  transition: fill .25s ease;
  margin-right: 14px;
}

.addColumnButton::after {
  display: none !important;
}

.filterTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableButtons {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  margin-right: 20px;
}

.filerButton {
  margin: 0;
  /* padding: 0; */
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  border: none;
  box-shadow: none;
  color: var(--green-text) !important;
}

.filerButton:hover {
  margin: 0;
  /* padding: 0; */
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: var(--dark-text) !important;
}

.filerButton:hover svg {
  fill: var(--dark-text);
}

.filerButton:last-child {
  /* margin-left: 39px; */
}

.filerButton svg {
  fill: var(--green-text);
  transition: fill .25s ease;
  margin-right: 7px;
}

.expandIcon {
  display: block;
  width: 25%;
  margin-bottom: -28px;
  margin-left: 18px;
  position: relative;
  z-index: 5;
}

.expandIcon svg {
  fill: var(--table-icons);
  transition: fill .25s ease;
}

.expandIcon:hover svg {
  fill: var(--dark-text);
}

.titleColumn {
  margin-bottom: 0;
  padding: 0 12px;
}

@media (max-width: 1300px) {
  .tableButtons {
    display: flex;
    margin-right: 300px;
  }
  .filerButton {
    padding: 6px 26px;
  }
}
@media (max-width: 1200px) {
  .filerButton {
    padding: 6px 16px;
  }
}
@media (max-width: 1100px) {
  .filerButton {
    padding: 6px;
  }
}
@media (max-width: 1000px) {
  .tableButtons {
    flex-direction: column;
    width: 200px;
    align-items: start;
    margin-right: 20px;
  }
  .filterTitle {
    display: block;
  }
}
@media (max-width: 500px) {
  .filterTitle {
    margin: 0 0 0 -120px;
  }
}