.subscribes-edit {
  padding-top: 4px;
  padding-bottom: 60px;
}

.subscribes-edit .ps__thumb-y {
  width: 16px!important;
  margin-right: -1px;
  background-color: #C4D3DB;
  border-radius: 4px;
}

.subscribes-edit .ps__rail-y {
  width: 16px;
  right: 0;
  background-color: #C4D3DB;
  border-radius: 4px;
  opacity: 1!important;
}

.subscribes-edit .ps__rail-y:hover > .ps__thumb-y,
.subscribes-edit .ps__rail-y.ps--clicking .ps__thumb-y  {
  background-color: #C4D3DB;
}

.subscribes-edit .ps__rail-y {
  height: 30px;
  background-color: #F3F6F8!important;
}

.subscribes-edit .ant-table-body {
  overflow-y: unset!important;
}

@media (max-width: 930px) {
  .editTariffForm .mg-input,
  .editTariffForm .ant-form-item-explain-error {
    width: 400px;
  }
}

@media (max-width: 510px) {
  .editTariffForm .mg-input,
  .editTariffForm .ant-form-item-explain-error {
    width: 300px;
  }
}

@media (max-width: 340px) {
  .editTariffForm .mg-input,
  .editTariffForm .ant-form-item-explain-error {
    width: 270px;
  }
}
